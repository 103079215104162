import isEmpty from "lodash/isEmpty";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Popup } from "semantic-ui-react";

import { useGetCustomerQuery } from "../api/customerApi";
import {
  MachineTranslateTaskQueue,
  DoneTranslateTextResponse,
} from "../api/machineTranslationApi";
import { Button, Icon, Toggle } from "../components/tailwind";
import { Text } from "../components/Text";
import { TranslateButton } from "../components/TranslateButton";
import {
  LanguageCode,
  SUICustomerLanguageDropdown,
} from "../customers/customerlanguages";
import { RootState } from "../utils/store";
import { PreviouslyMachineTranslatedTextRail } from "./PreviouslyMachineTranslatedTextRail";
import {
  ProofreadingInfo,
  Translation,
  TranslationInfo,
} from "./PreviouslyMachineTranslatedTextRailList";
import { FindAndReplaceModal } from "./FindAndReplaceModal";
import { popupDelay } from "../customers/gpt/types";
import { getFeatureFlag } from "../utils/featureFlags";
import { createHref } from "../utils/hrefUtils";
import { useSelectedText } from "../utils/reactCustomHooks";
import { MachineTranslateTextEmptyResults } from "./MachineTranslateTextEmptyResults";
import { MachineTranslateTextResults } from "./MachineTranslateTextResults";
import { useGetAuthQuery } from "../api/authApi";

export type TaskStatus = {
  loading: boolean;
  error: unknown;
  result: DoneTranslateTextResponse;
};

export type OverwrittenTextInfo = {
  text: string;
  translationEventId: number;
};

export const MachineTranslateText: React.FC = () => {
  const { data: customer } = useGetCustomerQuery();
  const { data: auth } = useGetAuthQuery();
  const token = useSelector((state: RootState) => state.auth.token);

  const ffNewTranslateButton = useMemo(() => {
    return getFeatureFlag(customer, "machine_translation_settings");
  }, [customer]);

  const machineTranslateTaskQueue = useMemo(() => {
    if (token)
      return new MachineTranslateTaskQueue<DoneTranslateTextResponse>(
        token,
        2000
      );
  }, [token]);
  const [autoSearchPrevTranslations, setAutoSearchPrevTranslations] = useState(
    true
  );
  const [sourceText, setSourceText] = useState<string>("");

  const { selectedText, onSelect, selectionPosition } = useSelectedText(
    sourceText
  );
  const [sourceContext, setSourceContext] = useState<string>("");

  const [selectedSourceLanguage, setSelectedSourceLanguage] = useState<
    LanguageCode
  >(null);
  const [selectedTargetLanguages, setSelectedTargetLanguages] = useState<
    LanguageCode[]
  >([]);

  const [proofreadingRequests, setProofreadingRequests] = useState<
    { [key in LanguageCode]?: ProofreadingInfo }
  >({});
  const [overwrittenTexts, setOverwrittenTexts] = useState<
    { [key in LanguageCode]?: OverwrittenTextInfo }
  >({});

  const [viewingProofreading, setViewingProofreading] = useState<
    LanguageCode[]
  >([]);

  const [FindAndReplaceModalOpen, setFindAndReplaceModalOpen] = useState<
    boolean
  >(false);

  const [translateStatus, setTranslateStatus] = useState<
    {
      [key in LanguageCode]?: TaskStatus;
    }
  >({});
  const [refreshTodaysTranslations, setRefreshTodaysTranslations] = useState(
    false
  );
  const haveTranslationJobs = useMemo((): boolean => {
    return Object.keys(translateStatus).length > 0;
  }, [translateStatus]);

  const loadPreviousTranslation = (info: TranslationInfo): void => {
    setViewingProofreading([]);
    setProofreadingRequests({});
    setSourceText(info.source_text);
    setSourceContext(info.source_context);
    setSelectedSourceLanguage(info.source_language);
    setSelectedTargetLanguages([]);
    setTranslateStatus({});
    setOverwrittenTexts({});
    (Object.entries(info.translations) as [
      LanguageCode,
      Translation
    ][]).forEach(([language, translation]) => {
      setSelectedTargetLanguages((prev) => [...prev, language]);
      if (translation.proofreading_info) {
        setProofreadingRequests((prev) => ({
          ...prev,
          [language]: translation.proofreading_info,
        }));
        if (translation.proofreading_info.done) {
          setViewingProofreading((prev) => [...prev, language]);
        }
      }
      setOverwrittenTexts((prev) => ({
        ...prev,
        [language]: {
          text: translation.overwritten_text,
          translationEventId: translation.translation_event_id,
        },
      }));
      setTranslateStatus((prev) => ({
        ...prev,
        [language]: {
          loading: false,
          error: null,
          result: {
            translated_text: translation.translated_text,
            translation_event_id: translation.translation_event_id,
          },
        },
      }));
    });
  };

  const startTranslate = async (configGroupId?: number): Promise<void> => {
    return machineTranslateTaskQueue
      .translateText(
        sourceText,
        sourceContext,
        selectedSourceLanguage,
        selectedTargetLanguages,
        configGroupId
      )
      .then((source_language: LanguageCode) => {
        setSelectedSourceLanguage(source_language);

        // Wait for all languages to be translated.
        const translateStatusObj: {
          [language: string]: TaskStatus;
        } = {};
        selectedTargetLanguages.forEach((language) => {
          translateStatusObj[language] = {
            loading: true,
            error: null,
            result: null,
          };
        });
        setTranslateStatus(translateStatusObj);

        selectedTargetLanguages.forEach((language) => {
          machineTranslateTaskQueue
            .waitUntilTranslateLanguageIsDone(language)
            .then((result) => {
              setRefreshTodaysTranslations(true);
              setTranslateStatus((prev) => ({
                ...prev,
                [language]: {
                  loading: false,
                  error: null,
                  result: result,
                },
              }));
            })
            .catch(() => {
              setTranslateStatus((prev) => ({
                ...prev,
                [language]: {
                  loading: false,
                  error: "Translation failed",
                  result: null,
                },
              }));
            });
        });
      });
  };

  const allTranslateJobsDone = useMemo((): boolean => {
    return Object.values(translateStatus).every((status) => !status.loading);
  }, [translateStatus]);

  const handleReset = (): void => {
    setSourceText("");
    setSourceContext("");
    setTranslateStatus({});
    setViewingProofreading([]);
    setProofreadingRequests({});
    setSelectedSourceLanguage(null);
    setSelectedTargetLanguages([]);
  };

  const showClearIcon = useMemo(
    () =>
      sourceText ||
      sourceContext ||
      !!Object.keys(translateStatus).length ||
      selectedSourceLanguage ||
      !!selectedTargetLanguages.length,
    [sourceText, sourceContext, translateStatus]
  );

  const handleMarkSelectionAsNoTranslate = (): void => {
    const selectedTextRemovedOldNoTranslate = selectedText
      .replaceAll("<notranslate>", "")
      .replaceAll("</notranslate>", "");
    const marked = `<notranslate>${selectedTextRemovedOldNoTranslate}</notranslate>`;
    const newSourceText =
      sourceText.substring(0, selectionPosition[0]) +
      marked +
      sourceText.substring(selectionPosition[1], sourceText.length);
    setSourceText(newSourceText);
  };

  return (
    <>
      <PreviouslyMachineTranslatedTextRail
        loadPreviousTranslation={loadPreviousTranslation}
        refreshTodaysTranslations={refreshTodaysTranslations}
        setRefreshTodaysTranslations={setRefreshTodaysTranslations}
      />
      <div className="tw-grid tw-grid-cols-[1fr_auto_1fr] tw-gap-8">
        <div>
          <SUICustomerLanguageDropdown
            clearable
            data-testid="machine-translate-text-source-dropdown"
            fluid
            onChange={(e, { value }): void => {
              setSelectedSourceLanguage(value as LanguageCode);
            }}
            placeholder="Detect language"
            selection
            value={selectedSourceLanguage}
          />
        </div>
        <div className="tw-pt-2">
          <Icon name="arrow_forward" />
        </div>
        <div>
          <SUICustomerLanguageDropdown
            clearable
            data-testid="machine-translate-text-target-dropdown"
            fluid
            multiple
            onChange={(_, { value }): void => {
              setSelectedTargetLanguages(value as LanguageCode[]);
            }}
            placeholder="Select language(s)"
            selection
            value={selectedTargetLanguages}
          />
        </div>
        <div className="-tw-mt-6">
          <div className="tw-relative">
            {showClearIcon && (
              <Popup
                size="tiny"
                content="Clear"
                mouseEnterDelay={popupDelay}
                trigger={
                  <span
                    onClick={handleReset}
                    className="tw-absolute tw-right-0 tw-h-[40px] tw-w-[40px] tw-cursor-pointer tw-grid tw-place-items-center tw-top-9"
                  >
                    <Icon
                      data-testid="machine-translate-text-clear-icon"
                      name="close"
                    />
                  </span>
                }
              />
            )}
            <div className="tw-flex tw-gap-4 tw-mb-2">
              <Button
                content="Mark selection as no-translate"
                compact
                size="sm"
                disabled={!selectedText}
                onClick={handleMarkSelectionAsNoTranslate}
              />
              <Toggle
                label="Auto search Previous Translations"
                checked={autoSearchPrevTranslations}
                setChecked={setAutoSearchPrevTranslations}
              />
            </div>
            <textarea
              className="txu-textarea !tw-min-h-52"
              data-testid="machine-translate-textarea"
              onChange={(e): void => setSourceText(e.target.value)}
              onSelect={onSelect}
              value={sourceText}
            />
          </div>
          <Text lessMargin>
            Additional context
            <span
              className="ui text small grey"
              style={{ marginInline: "5px" }}
            >
              (not translated)
            </span>
            <Popup
              wide
              trigger={
                <Icon name="help" className="tw-align-middle tw-text-lg" />
              }
            >
              The context field makes it possible to include additional context
              that can influence a translation but is not translated itself.
              This additional context can potentially improve translation
              quality when translating short, low-context source texts such as
              product names on an e-commerce website, article headlines on a
              news website, or UI elements.
            </Popup>
          </Text>
          <textarea
            className="txu-textarea"
            data-testid="machine-translate-context-textarea"
            onChange={(e): void => setSourceContext(e.target.value)}
            value={sourceContext}
          />
          <div className="tw-flex tw-items-start tw-justify-end tw-gap-1">
            <FindAndReplaceModal
              open={FindAndReplaceModalOpen}
              setOpen={setFindAndReplaceModalOpen}
            />

            <div>
              {(auth?.credential?.role_is_superuser ||
                auth?.user?.is_staff) && (
                <>
                  <h5 className=" tw-text-gray-500 tw-mb-2">Text Control</h5>
                  <div className="tw-grid tw-grid-cols-[auto,_1fr] tw-items-start tw-mr-auto tw-gap-4 tw-gap-y-8 tw-text-pretty">
                    <Button
                      size="sm"
                      content="No-translate word list..."
                      variant="secondary-alt"
                      onClick={(): void =>
                        window.openSubpage(
                          null,
                          createHref("noTranslateWordList", customer)
                        )
                      }
                    />
                    <p className="tw-m-0 tw-text-gray-500 tw-text-sm">
                      Specify words or terms which should never be translated,
                      use to preserve names or specific terminology.
                    </p>
                    <Button
                      size="sm"
                      content="Glossaries..."
                      variant="secondary-alt"
                      onClick={(): void =>
                        window.openSubpage(
                          null,
                          createHref("glossaries", customer)
                        )
                      }
                    />
                    <p className="tw-m-0 tw-text-gray-500 tw-text-sm">
                      Specify how words and terms should be translated in a
                      specific language pair.
                    </p>
                    <Button
                      size="sm"
                      content="Word Replacements..."
                      variant="secondary-alt"
                      onClick={(): void => setFindAndReplaceModalOpen(true)}
                    />
                    <p className="tw-m-0 tw-text-gray-500 tw-text-sm">
                      Automatically apply find and replace rules on the final
                      text as post-processing.
                    </p>
                  </div>
                </>
              )}
            </div>

            {ffNewTranslateButton ? (
              <TranslateButton
                data-testid="machine-translate-text-translate-button"
                disabled={
                  isEmpty(selectedTargetLanguages) ||
                  isEmpty(sourceText) ||
                  selectedSourceLanguage === selectedTargetLanguages[0] ||
                  !allTranslateJobsDone
                }
                loading={haveTranslationJobs && !allTranslateJobsDone}
                variant="primary"
                onClick={(configGroupId): void => {
                  startTranslate(configGroupId);
                }}
              >
                Translate
              </TranslateButton>
            ) : (
              <Button
                variant="primary"
                content="Translate"
                data-testid="machine-translate-text-translate-button"
                disabled={
                  isEmpty(selectedTargetLanguages) ||
                  isEmpty(sourceText) ||
                  selectedSourceLanguage === selectedTargetLanguages[0] ||
                  !allTranslateJobsDone
                }
                onClick={(): void => {
                  startTranslate();
                }}
                loading={haveTranslationJobs && !allTranslateJobsDone}
              />
            )}
          </div>
        </div>
        <div />
        <div>
          {!haveTranslationJobs ? (
            <MachineTranslateTextEmptyResults
              sourceText={sourceText}
              selectedSourceLanguage={selectedSourceLanguage}
              autoSearchPrevTranslations={autoSearchPrevTranslations}
              loadPreviousTranslation={loadPreviousTranslation}
            />
          ) : (
            <MachineTranslateTextResults
              translateStatus={translateStatus}
              setTranslateStatus={setTranslateStatus}
              viewingProofreading={viewingProofreading}
              setViewingProofreading={setViewingProofreading}
              proofreadingRequests={proofreadingRequests}
              overwrittenTexts={overwrittenTexts}
              sourceText={sourceText}
              selectedSourceLanguage={selectedSourceLanguage}
              setRefreshTodaysTranslations={setRefreshTodaysTranslations}
              allTranslateJobsDone={allTranslateJobsDone}
              machineTranslateTaskQueue={machineTranslateTaskQueue}
            />
          )}
        </div>
      </div>
    </>
  );
};
