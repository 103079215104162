import React, { useState } from "react";
import { Button } from "../../../components/tailwind/button/Button";

const FormInput: React.FC = () => (
  <div className="tw-grid tw-grid-cols-2 tw-gap-12">
    <input
      type="text"
      className="txu-input tw-my-1"
      placeholder="e.g. Hello!"
      name="sources"
    />
    <input
      type="text"
      className="txu-input tw-my-1"
      placeholder="e.g. Guten Tag!"
      name="targets"
    />
  </div>
);
/**
 * This component is only used in templates/manage/glossaries/detail.html
 *
 * It serves that view with empty form inputs.
 *
 * The form in the view is responsible for sending a form request.
 */
export const EmptyFormInputs: React.FC = () => {
  const [amountOfInputs, setAmountOfInputs] = useState(1);
  return (
    <>
      {Array.from({ length: amountOfInputs }, (_, i) => i).map((number) => (
        <FormInput key={number} />
      ))}
      <div className="txu-divider"></div>
      <Button
        type="button"
        variant="primary-alt"
        className="tw-float-right"
        onClick={(): void => setAmountOfInputs((prev) => prev + 1)}
      >
        Add row
      </Button>
    </>
  );
};
