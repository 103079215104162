import React, { useMemo } from "react";

import { useGetCustomerQuery } from "../api/customerApi";
import { useGetMachineTranslationConfigsQuery } from "../api/machineTranslationConfigSlice";
import { Button, Dropdown, Icon } from "./tailwind";
import { Props as ButtonProps } from "./tailwind/button/Button";
import { createHref } from "../utils/hrefUtils";

type Props = Omit<ButtonProps, "onClick"> & {
  content?: React.ReactNode | never;
  onClick: (configGroupId?: number) => void | Promise<void>;
  "data-testid"?: string;
};

export const TranslateButton: React.FC<Props> = ({
  content,
  onClick,
  children,
  "data-testid": dataTestId,
  ...buttonProps
}) => {
  const { data: customer } = useGetCustomerQuery();

  const {
    data: configGroups = [],
    isLoading: isFetchingGroups,
  } = useGetMachineTranslationConfigsQuery(undefined);

  const defaultConfigGroupId = useMemo(() => {
    if (!configGroups.length) return undefined;
    const defaultGroup = configGroups.find((group) => group.is_default);
    return defaultGroup?.id;
  }, [configGroups]);

  const testId = useMemo(() => dataTestId || "translate-button", [dataTestId]);

  if (!isFetchingGroups && configGroups.length < 2) {
    return (
      <Button
        {...buttonProps}
        data-testid={testId}
        onClick={(): void => {
          onClick(defaultConfigGroupId);
        }}
      >
        {content || children}
      </Button>
    );
  }
  return (
    <>
      <span
        data-testid={testId}
        className={`txu-button-group ${
          isFetchingGroups ? "txu-loading" : ""
        } tw-inline-flex tw-w-max tw-items-stretch`}
      >
        <Button
          {...buttonProps}
          variant={buttonProps?.variant ?? "secondary-alt"}
          className="tw-rounded-none tw-rounded-s !tw-border-e-0"
          onClick={(): void => {
            onClick(defaultConfigGroupId);
          }}
        >
          {content || children}
        </Button>

        <Dropdown
          control="button"
          options={[
            {
              value: 0,
              key: "header-1",
              text: "Translation Settings Groups...",
              type: "link",
              href: createHref("machineTranslationConfigGroup", customer),
            },
            ...configGroups.map((group) => ({
              value: group.id,
              key: group.id.toString(),
              text: group.name,
            })),
          ]}
          value={0}
          onChange={(value): void => {
            onClick(value as number);
          }}
          content={<Icon name="expand_more" />}
          buttonProps={{
            ...buttonProps,
            className: "tw-rounded-none tw-rounded-e txu-compact tw-h-full",
            icon: true,
            variant: buttonProps?.variant ?? "secondary-alt",
          }}
          size={buttonProps.size}
        />
      </span>
    </>
  );
};
