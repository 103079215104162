import { Customer } from "../customers/Customer";

const URLS = {
  assistants: "manage/gpt/assistants",
  promptSelectionRules: "manage/gpt/prompt-selection-rules",
  promptSuggestions: "manage/gpt/prompt-suggestions",
  fieldsets: "manage/fieldsets",
  documentStructures: "manage/planner/document-structures",
  templateLabels: "manage/vocabulary/template-labels",
  FindAndReplace: "manage/find-and-replace",
  glossaries: "manage/glossaries",
  noTranslateWordList: "manage/no-translate-list",
  machineTranslationConfigGroup:
    "manage/machine-translation/machine-translation-config-group/",
} as const;

export const createHref = (
  key: keyof typeof URLS,
  customer: Customer
): string => {
  let customerSlug = "_";
  if (customer?.slug) {
    customerSlug = customer.slug;
  }
  const url = URLS[key];
  return `/d/${customerSlug}/${url}`;
};
