import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Breadcrumb,
  Button,
  Dropdown,
  Icon,
  Menu,
  Modal,
  Popup,
} from "semantic-ui-react";

import styled from "styled-components";

import { SelectPromptDropdown } from "./SelectPromptDropdown";
import { Text } from "../../../components/Text";
import { TranslateButton } from "../../../components/TranslateButton";
import { PromptForm } from "../PromptForm";
import { Prompt, PromptFormMode, PromptGroup, Sentence } from "../types";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
} from "../CopyAssistantContext/CopyAssistantContext";
import { PromptGroupForm } from "../PromptGroupForm";
import { SelectPromptGroupDropdown } from "./SelectPromptGroupDropdown";
import { CopyAssistantActionType } from "../CopyAssistantContext/types";
import { toolbarTopTestIds } from "../testUtils/testIdsSelectors";
import { useGetAuthQuery } from "../../../api/authApi";
import {
  useGetDocumentSections,
  useGetDocumentStructures,
} from "../../../planner/document-structure/manage/customhooks";
import {
  CreateTextBlock,
  createTextBlock,
  setActiveTextBlock,
} from "../../../api/textBlockApi";
import { ProductId } from "../../product";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { LanguageCode } from "../../../customers/customerlanguages";
import { useSelector } from "react-redux";
import { getFeatureFlag } from "../../../utils/featureFlags";
import { RootState, store } from "../../../utils/store";
import { generateId } from "../../../utils/uuidUtils";
import { LangStringObject } from "../../../vocabulary/LangString";
import {
  findConnectedChannelsForSection,
  getCustomerEnglishLanguage,
} from "../utils";
import { reloadPreview } from "../../../legacy/t.product-detail";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../../api/djangoToastSlice";
import { getMachineTranslatedText } from "../../../api/vocabularyApi";
import { gptChangeTagValue } from "../../../api/gptApi";

export const FittedDropdownAsMenuItem = styled(Dropdown)<{ $bold?: boolean }>`
  &&&&& {
    > div.text {
      font-size: small;
      ${({ $bold }): string => $bold && "font-weight: bold;"}
    }
  }
`;

const dropdowns = {
  Prompt: <SelectPromptDropdown />,
  "Prompt Groups": <SelectPromptGroupDropdown />,
};
type DropdownKeys = keyof typeof dropdowns;

const contextDropdownOptions = [
  { key: "PROMPT", value: "Prompt", text: "Prompt" },
  {
    key: "GROUPS",
    value: "Prompt Groups",
    text: "Prompt Groups",
  },
];

type Props = {
  refreshPromptList: () => Promise<void>;
  refetchGroups: () => Promise<void>;
  productId: ProductId;
};

export const ToolbarTop: React.FC<Props> = ({
  refreshPromptList,
  refetchGroups,
  productId,
}) => {
  const {
    prompts: {
      customerOwned: customerOwnedPrompts,
      availableTags: { customerOwned, systemOwned },
      isLoading: fetchingPrompts,
      selected: selectedPrompt,
    },
    groups: {
      availableTags: groupTags,
      selected: selectedGroup,
      isLoading: fetchingGroups,
    },
    sentences: { isLoading: sentenceIsLoading, list: sentences },
  } = useContext(CopyAssistantContext);
  const dispatch = useContext(CopyAssistantDispatchContext);
  const { data: auth, isLoading: isAuthLoading } = useGetAuthQuery();

  const token = useSelector((state: RootState) => state.auth.token);
  const {
    data: customer,
    isLoading: isCustomerLoading,
  } = useGetCustomerQuery();
  const {
    data: sections,
    isFetching: isFetchingSections,
  } = useGetDocumentSections();

  const {
    data: structures,
    isFetching: isFetchingStructures,
  } = useGetDocumentStructures();

  const sectionDropdownItems = useMemo(() => {
    if (!sections || isFetchingSections) return [];
    return sections
      .filter(({ template_label_ids }) => !template_label_ids)
      .map(({ id, name }) => ({
        value: id,
        text: name,
        key: id,
      }));
  }, [sections, isFetchingSections]);

  const activeSentences = useMemo(() => {
    return sentences.filter(
      (sentence) => sentence?.sectionInfo && sentence.sectionInfo.active
    );
  }, [sentences]);

  const someSentenceIsLoading = useMemo(() => {
    return sentences.some((sentence) => sentence.isLoading);
  }, [sentences]);

  const isStaff = useMemo(() => {
    if (isAuthLoading) return false;
    return auth?.user?.is_staff;
  }, [auth, isAuthLoading]);

  const [
    hasGeneratedWithSystemPrompt,
    setHasGeneratedWithSystemPrompt,
  ] = useState(false);

  const [generationHasHappened, setGenerationHasHappened] = useState(false);
  const [oldSentenceLength, setOldSentenceLength] = useState(0);
  const [
    selectedPromptWhenGenerating,
    setSelectedPromptWhenGenerating,
  ] = useState<Prompt | null>(null);
  useEffect(() => {
    if (sentenceIsLoading) {
      setSelectedPromptWhenGenerating(selectedPrompt);
    }
    if (!sentenceIsLoading) {
      if (
        oldSentenceLength !== sentences.length &&
        selectedPromptWhenGenerating &&
        !selectedPromptWhenGenerating?.customer_id
      ) {
        setGenerationHasHappened(true);
      }
      setOldSentenceLength(sentences.length);
    }
  }, [sentenceIsLoading, sentences]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | number = null;
    if (hasGeneratedWithSystemPrompt) {
      timeoutId = setTimeout(() => {
        setHasGeneratedWithSystemPrompt(false);
      }, 5000);
    } else {
      setHasGeneratedWithSystemPrompt(generationHasHappened);
      setGenerationHasHappened(false);
    }
    return () => {
      clearTimeout(timeoutId as number);
    };
  }, [hasGeneratedWithSystemPrompt, generationHasHappened]);

  const [openPromptFormModal, setOpenPromptFormModal] = useState(false);
  const [openGroupFormModal, setOpenGroupFormModal] = useState(false);
  const [formMode, setFormMode] = useState<PromptFormMode>(
    PromptFormMode.CREATE
  );

  const [context, setContext] = useState<DropdownKeys>("Prompt");

  useEffect(() => {
    if (selectedPrompt && context !== "Prompt") {
      setContext("Prompt");
    } else if (selectedGroup && context !== "Prompt Groups") {
      setContext("Prompt Groups");
    }
  }, [selectedPrompt, selectedGroup]);

  const showAdditionalOptions = useMemo(() => {
    if (context === "Prompt") return !!selectedPrompt;
    return !!selectedGroup;
  }, [selectedPrompt, selectedGroup, context]);

  const showSettingsOption = useMemo(() => {
    if (context === "Prompt") return !!selectedPrompt?.customer_id || isStaff;
    return true;
  }, [selectedPrompt, selectedGroup, isStaff]);

  const handleOpenForm = (mode: PromptFormMode): void => {
    setFormMode(mode);
    if (context === "Prompt") {
      setOpenPromptFormModal(true);
    } else {
      setOpenGroupFormModal(true);
    }
  };

  const handleSelectPrompt = (prompt: Prompt): void => {
    dispatch({
      payload: prompt,
      type: CopyAssistantActionType.SELECT_PROMPT,
    });
  };

  const handleSelectGroup = (group: PromptGroup): void => {
    dispatch({
      payload: group,
      type: CopyAssistantActionType.SELECT_GROUP,
    });
  };
  const handleAddManualTextBlock = async (sectionId: number): Promise<void> => {
    const language = customer.config.tag_input_language as LanguageCode;
    const newTextBlock: CreateTextBlock = {
      product: productId,
      section: sectionId,
      language: language,
      text: "",
      active: true,
      prompt_id: null,
      translated_from: null,
    };

    const createdTextblock = await createTextBlock(token, newTextBlock);
    await setActiveTextBlock(token, createdTextblock.id);
    const langStringValues: LangStringObject = {};
    customer.languages.forEach((language) => {
      if (language.code == createdTextblock.language) {
        langStringValues[language.code] = createdTextblock.text;
      } else {
        langStringValues[language.code] = "";
      }
    });
    const sentence: Sentence = {
      id: generateId(),
      value: langStringValues,
      language: language,
      generationInfo: {
        promptId: undefined,
      },
      sentenceGroupId: undefined,
      sectionInfo: {
        documentSectionId: sectionId,
        documentSectionName: sections.find(
          (section) => section.id === sectionId
        )?.name,
        textBlockId: createdTextblock.id,
        langStringValues: langStringValues,
        connectedChannels: findConnectedChannelsForSection(
          sectionId,
          customer,
          structures
        ),
        active: createdTextblock.active,
      },
    };
    dispatch({
      type: CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST,
      payload: sentence.sectionInfo.documentSectionName,
    });
    dispatch({ type: CopyAssistantActionType.ADD_SENTENCE, payload: sentence });
    dispatch({
      type: CopyAssistantActionType.SET_SECTION_ACTIVE,
      payload: { id: sentence.id },
    });
    reloadPreview(sentence.sectionInfo.connectedChannels.map(({ id }) => id));
  };

  const handleTranslateActiveSentence = async (
    sentence: Sentence,
    configGroupId?: number
  ): Promise<void> => {
    let successes = 0;
    let failures = 0;
    const promises: Promise<unknown>[] = [];
    const newTranslations: LangStringObject = {};
    const sourceLanguageCode =
      sentence.language || getCustomerEnglishLanguage(customer)?.code;
    const sourceText = sentence.value[sourceLanguageCode];
    if (!sourceText) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.ERROR,
          content: "Found no Source text",
        })
      );
      return;
    }

    customer.languages.forEach(({ code: targetLanguageCode }) => {
      if (targetLanguageCode === sourceLanguageCode) return;
      promises.push(
        getMachineTranslatedText({
          sourceLanguage: sourceLanguageCode,
          targetLanguage: targetLanguageCode,
          text: sourceText,
          token,
          configGroupId,
        })
          .then((text) => {
            successes += 1;
            newTranslations[targetLanguageCode] = text;
          })
          .catch(() => {
            failures += 1;
          })
      );
    });
    await Promise.all(promises);

    if (failures && !successes) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.ERROR,
          content: `Failed to translate ${failures} Language(s).`,
        })
      );
    } else if (failures && successes) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.WARNING,
          content: `Translated ${successes} Language(s). Failed to translate ${failures} Language(s).`,
        })
      );
    } else if (successes) {
      store.dispatch(
        setDjangoToastOpen({
          appearance: NotificationAppearance.SUCCESS,
          content: `Translated ${successes} Language(s).`,
        })
      );
    }
    const updatedLangStringObject = {
      ...sentence.value,
      ...newTranslations,
    };
    Object.entries(updatedLangStringObject).forEach(
      ([languageCode, value]: [LanguageCode, string]) => {
        dispatch({
          type: CopyAssistantActionType.UPDATE_SENTENCE,
          payload: {
            id: sentence.id,
            toUpdate: { value: { [languageCode]: value } },
          },
        });
      }
    );
    await gptChangeTagValue({
      productId,
      value: updatedLangStringObject,
      templateId: undefined,
      tagId: undefined,
      textBlockId: sentence.sectionInfo?.textBlockId,
      token,
    });
  };

  const handleTranslateAllActiveSentences = (configGroupId?: number): void => {
    const promises = activeSentences.map((sentence) => {
      dispatch({
        type: CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING,
        payload: { id: sentence.id, isLoading: true, reason: "Translating" },
      });
      return handleTranslateActiveSentence(sentence, configGroupId).finally(
        () => {
          dispatch({
            type: CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING,
            payload: { id: sentence.id, isLoading: false },
          });
        }
      );
    });
    Promise.all(promises).finally(() => {
      reloadPreview();
    });
  };

  const isCreating = formMode === PromptFormMode.CREATE;

  const ffNewTranslateButton = useMemo(() => {
    return getFeatureFlag(customer, "machine_translation_settings");
  }, [customer]);

  return (
    <>
      <Menu size="small" className="tw-flex-wrap">
        <Menu.Item>
          <Breadcrumb size="small">
            <Breadcrumb.Section>
              <FittedDropdownAsMenuItem
                data-testid={toolbarTopTestIds.contextDropdown}
                floating
                text={context}
                options={contextDropdownOptions}
                value={context}
                onChange={(e: any, { value }: { value: DropdownKeys }): void =>
                  setContext(value)
                }
              />
            </Breadcrumb.Section>
            <Breadcrumb.Divider
              icon="right angle"
              style={{ marginInline: "10px" }}
            />
            <Breadcrumb.Section active>{dropdowns[context]}</Breadcrumb.Section>
          </Breadcrumb>
        </Menu.Item>

        {showAdditionalOptions && (
          <>
            {showSettingsOption && (
              <Menu.Item
                data-testid={toolbarTopTestIds.buttons.settings}
                link
                onClick={(): void => handleOpenForm(PromptFormMode.UPDATE)}
                disabled={fetchingPrompts || fetchingGroups}
              >
                <Text compact size="small">
                  <Icon name="setting" color="red" /> Settings
                </Text>
              </Menu.Item>
            )}
            <Popup
              content="Use this to copy a System Prompt into My Prompts and tweak it to your liking."
              wide="very"
              size="small"
              position="top left"
              open={hasGeneratedWithSystemPrompt}
              trigger={
                <Menu.Item
                  data-testid={toolbarTopTestIds.buttons.copy}
                  link
                  onClick={(): void => handleOpenForm(PromptFormMode.COPY)}
                  disabled={fetchingPrompts || fetchingGroups}
                >
                  <Text compact size="small">
                    <Icon name="copy" color="red" /> Copy
                  </Text>
                </Menu.Item>
              }
            />
          </>
        )}
        <Menu.Item
          data-testid={toolbarTopTestIds.buttons.create}
          link
          onClick={(): void => handleOpenForm(PromptFormMode.CREATE)}
        >
          <Text compact size="small">
            <Icon name="plus" color="red" /> Create
          </Text>
        </Menu.Item>
        <Menu.Menu position="right" size="small">
          <Menu.Item>
            {ffNewTranslateButton ? (
              <TranslateButton
                data-testid={
                  toolbarTopTestIds.buttons.translateAllActiveTextBlocks
                }
                disabled={!activeSentences.length || someSentenceIsLoading}
                compact
                variant="primary-alt"
                size="sm"
                content="Translate all active text blocks"
                onClick={(configGroupId): void => {
                  handleTranslateAllActiveSentences(configGroupId);
                }}
              />
            ) : (
              <Button
                style={{ fontSize: ".9em" }}
                data-testid={
                  toolbarTopTestIds.buttons.translateAllActiveTextBlocks
                }
                disabled={!activeSentences.length || someSentenceIsLoading}
                compact
                size="tiny"
                color="red"
                content="Translate all active text blocks"
                basic
                onClick={(): void => handleTranslateAllActiveSentences()}
              />
            )}
          </Menu.Item>
          <Menu.Item>
            <FittedDropdownAsMenuItem
              $bold
              loading={
                isFetchingSections || isFetchingStructures || isCustomerLoading
              }
              text="Add empty text block"
              value=""
              header="Add text block to section"
              options={sectionDropdownItems}
              onChange={(e: any, { value }: { value: number }): Promise<void> =>
                handleAddManualTextBlock(value)
              }
              upward={false}
              scrolling
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Modal
        open={openPromptFormModal}
        onClose={(): void => setOpenPromptFormModal(false)}
      >
        <PromptForm
          userIsStaff={isStaff}
          mode={formMode}
          setOpenModal={setOpenPromptFormModal}
          refreshPromptList={refreshPromptList}
          availableTags={customerOwned}
          systemTags={systemOwned}
          prompt={isCreating ? null : selectedPrompt}
          selectPrompt={handleSelectPrompt}
        />
      </Modal>
      <Modal
        open={openGroupFormModal}
        onClose={(): void => setOpenGroupFormModal(false)}
      >
        <PromptGroupForm
          prompts={customerOwnedPrompts}
          mode={formMode}
          isLoading={fetchingPrompts}
          availableTags={groupTags}
          setOpenModal={setOpenGroupFormModal}
          reFetchGroups={refetchGroups}
          selectedPromptGroup={isCreating ? null : selectedGroup}
          selectGroup={handleSelectGroup}
        />
      </Modal>
    </>
  );
};
