/* eslint-disable react/function-component-definition */
import ReactDOM from "react-dom";
import React, {
  useMemo,
  useState,
  forwardRef,
  ForwardedRef,
  useRef,
} from "react";
import escapeRegExp from "lodash/escapeRegExp";
import {
  autoPlacement,
  autoUpdate,
  offset,
  size,
  useFloating,
} from "@floating-ui/react";

import { Button, Props as ButtonProps } from "../button/Button";
import { uuidv4 } from "../../../utils/uuidUtils";
import { Badge, Props as BadgeProps } from "../badge/Badge";

const twSize = (size: Size, defaultSize?: string): string => {
  switch (size) {
    case "lg":
      return "tw-text-lg";
    case "sm":
      return "tw-text-sm";
    default:
      return defaultSize || "tw-text-base";
  }
};

export type DropdownControls = "button" | "search" | "multi-search";
type Size = "lg" | "sm";

type OptionType = "option" | "header" | "link";
export type Option<TValue> = {
  key: string;
  value: TValue;
  text: string;
  caption?: string;
  type?: OptionType;
  disabled?: boolean;
  href?: string;
};

type RenderOptions<TValue> = (
  options: Option<TValue>[],
  onClick: (value: TValue) => void,
  dropdownItemId?: string,
  onBlur?: (relatedTarget: EventTarget & Element) => void,
  searchValue?: string,
  onAddItem?: (value: string) => void
) => JSX.Element;

type Props<TValue> = {
  options: Option<TValue>[];
  placeholder?: string;
  size?: Size;
  disabled?: boolean;
  testIdBase?: string;
  control?: DropdownControls;
  clearable?: boolean | never;
  buttonProps?: ButtonProps | never;
  content?: React.ReactNode | never;
  isOpenCallback?: (open: boolean) => void;
  onChange: (value: TValue | TValue[]) => void;
  badgeProps?: Omit<BadgeProps, "content" | "onClick">;
  allowAdditions?: boolean | never;
  onAddItem?: (value: string) => void | never;
};

type SearchDropdownProps<TValue> = Props<TValue> & {
  control?: "search";
  placeholder?: string;
  clearable?: boolean;
  buttonProps?: never;
  content?: never;
  value: TValue;
  allowAdditions?: boolean;
  onAddItem?: (value: TValue) => void;
};

type MultiSearchDropdownProps<TValue> = Props<TValue> & {
  control: "multi-search";
  placeholder?: string;
  clearable?: boolean;
  buttonProps?: never;
  content?: never;
  value: TValue[];
  allowAdditions?: boolean;
  onAddItem?: (value: TValue) => void;
};

type ButtonDropdownProps<TValue> = Props<TValue> & {
  control: "button";
  clearable?: never;
  placeholder?: never;
  allowAddition?: never;
  onAddItem?: never;
  buttonProps: Omit<ButtonProps, "onClick" | "onChange">;
  content: React.ReactNode;
  value: TValue;
};

/**
 * default data-testid:
 * - input:           `dropdown-input-control`
 * - clear selection: `dropdown-clear-selection`
 * - menu:            `dropdown-menu`
 * - no result:       `dropdown-item-no-result`
 * - item:            `dropdown-item-${index}`
 *
 * When specifying testIdBase all above will be prepended with `${testIdBase}-`
 *
 * This component can strongly type the value in option by using the component like this `<Dropdown<Type>>`.
 */
export function Dropdown<TValue = string | number>({
  control = "search",
  value,
  testIdBase,
  buttonProps,
  clearable,
  placeholder,
  content,
  isOpenCallback,
  allowAdditions,
  onAddItem,
  ...rest
}:
  | SearchDropdownProps<TValue>
  | MultiSearchDropdownProps<TValue>
  | ButtonDropdownProps<TValue>): JSX.Element {
  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: "fixed",
    middleware: [
      size(({ elements, rects }) => {
        if (rects.reference.width > rects.floating.width) {
          elements.floating.style.width = `${rects.reference.width}px`;
        }
        return {};
      }),
      autoPlacement({
        allowedPlacements: ["top", "bottom", "top-start", "bottom-start"],
      }),
      offset(4),
    ],
  });
  const [isOpen, setIsOpen] = useState(false);

  const renderOptions = (
    options: Option<TValue>[],
    onClick: (value: TValue) => void,
    dropdownItemId?: string,
    onBlur?: (relatedTarget: EventTarget & Element) => void,
    searchValue?: string,
    onAddItem?: (value: string) => void
  ): JSX.Element => {
    const portalDestination =
      document.querySelector("dialog[open]") || document.body;
    return ReactDOM.createPortal(
      <div className="tw-relative">
        {isOpen && (
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={[
              "tw-z-50",
              "tw-flex",
              "tw-max-h-96",
              "tw-flex-col",
              "tw-overflow-y-auto",
              "tw-rounded-md",
              "tw-border",
              "tw-border-gray-300",
              "tw-bg-white",
              "tw-shadow",
              "tw-overscroll-contain",
              twSize(rest?.size),
            ].join(" ")}
            data-testid={
              testIdBase ? `${testIdBase}-dropdown-menu` : "dropdown-menu"
            }
          >
            {!options.length && !allowAdditions && (
              <div
                className="tw-p-3 tw-text-center tw-text-gray-400"
                data-testid={
                  testIdBase
                    ? `${testIdBase}-dropdown-item-no-result`
                    : "dropdown-item-no-result"
                }
              >
                No results found
              </div>
            )}
            {!options.length && allowAdditions && !searchValue && (
              <div
                className="tw-p-3 tw-text-center tw-text-gray-400"
                data-testid={
                  testIdBase
                    ? `${testIdBase}-dropdown-item-no-result`
                    : "dropdown-item-no-result"
                }
              >
                No results found. Start typing to add a option
              </div>
            )}
            {options.map((opt, index) => {
              const isNotAtTheTop = index > 0;
              if (opt?.type === "link") {
                return (
                  <div
                    data-testid={
                      testIdBase
                        ? `${testIdBase}-dropdown-link-${index}`
                        : `dropdown-link-${index}`
                    }
                    id={dropdownItemId}
                    tabIndex={-1}
                    key={opt.key}
                    className={[
                      "tw-cursor-pointer",
                      "tw-shadow-sm",
                      "tw-border-b",
                      "tw-border-gray-100",
                      "tw-px-3",
                      "tw-py-3",
                      "tw-text-center",
                      "tw-text-blue-500",
                      isNotAtTheTop ? "tw-border-t" : "",
                    ].join(" ")}
                    onBlur={(event): void => {
                      onBlur(event.relatedTarget);
                    }}
                    onClick={(): void => {
                      window.location.href = opt.href;
                    }}
                  >
                    {opt.text}
                  </div>
                );
              }
              if (opt?.type === "header") {
                return (
                  <div
                    data-testid={
                      testIdBase
                        ? `${testIdBase}-dropdown-header-${index}`
                        : `dropdown-header-${index}`
                    }
                    id={dropdownItemId}
                    tabIndex={-1}
                    key={opt.key}
                    className={[
                      "tw-shadow-sm",
                      "tw-border-b",
                      "tw-border-gray-100",
                      "tw-px-3",
                      "tw-py-3",
                      "tw-text-center",
                      "tw-text-gray-400",
                      isNotAtTheTop ? "tw-border-t" : "",
                    ].join(" ")}
                    onBlur={(event): void => {
                      onBlur(event.relatedTarget);
                    }}
                  >
                    {opt.text}
                  </div>
                );
              }

              return (
                <div
                  role="option"
                  data-testid={
                    testIdBase
                      ? `${testIdBase}-dropdown-item-${index}`
                      : `dropdown-item-${index}`
                  }
                  id={dropdownItemId}
                  key={opt.key}
                  tabIndex={index}
                  className={[
                    "tw-cursor-pointer",
                    "tw-px-3",
                    "tw-py-2",
                    "tw-transition-colors",
                    "hover:tw-bg-gray-100",
                    opt.value === value
                      ? "tw-font-semibold tw-bg-gray-100"
                      : "tw-bg-inherit",
                    opt?.disabled
                      ? "tw-text-gray-500 hover:tw-bg-inherit tw-bg-gray-200"
                      : "",
                  ].join(" ")}
                  onClick={(): void => {
                    if (opt?.disabled) return;
                    onClick(opt.value);
                  }}
                  onBlur={(event): void => {
                    onBlur?.(event.relatedTarget);
                  }}
                  onKeyDown={(event): void => {
                    if (opt?.disabled) return;
                    if (event.key === "Enter") {
                      onClick(opt.value);
                    }
                  }}
                >
                  {opt?.caption ? (
                    <span className="tw-flex tw-flex-col">
                      <span>
                        {opt.text}
                        {opt.value == value && (
                          <i className="material-icons tw-float-end tw-ms-4 tw-text-lg tw-leading-[1]">
                            check
                          </i>
                        )}
                      </span>
                      <small className="tw-text-gray-500 tw-w-72">
                        {opt?.caption}
                      </small>
                    </span>
                  ) : (
                    <>
                      {opt.text}
                      {opt.value == value && (
                        <i className="material-icons tw-float-end tw-ms-4 tw-text-lg tw-leading-[1]">
                          check
                        </i>
                      )}
                    </>
                  )}
                </div>
              );
            })}
            {searchValue && allowAdditions && (
              <div
                role="option"
                tabIndex={1}
                id={dropdownItemId}
                className={[
                  "tw-cursor-pointer",
                  "tw-px-3",
                  "tw-py-2",
                  "tw-transition-colors",
                  "hover:tw-bg-gray-100",
                ].join(" ")}
                onClick={(): void => {
                  onAddItem?.(searchValue);
                }}
                onBlur={(event): void => {
                  onBlur?.(event.relatedTarget);
                }}
                data-testid={
                  testIdBase
                    ? `${testIdBase}-dropdown-item-add`
                    : "dropdown-item-add"
                }
              >
                <span className="tw-text-gray-400">Add</span> &quot;
                {searchValue}&quot;
              </div>
            )}
          </div>
        )}
      </div>,
      portalDestination
    );
  };

  switch (control) {
    case "search":
      return (
        <SearchDropdown<TValue>
          ref={refs.setReference}
          value={value as TValue}
          testIdBase={testIdBase}
          renderOptions={renderOptions}
          setIsOpen={(open): void => {
            setIsOpen(open);
            isOpenCallback?.(open);
          }}
          clearable={clearable}
          placeholder={placeholder}
          allowAdditions={allowAdditions}
          onAddItem={onAddItem}
          {...rest}
        />
      );
    case "button":
      return (
        <ButtonDropdown<TValue>
          ref={refs.setReference}
          value={value as TValue}
          testIdBase={testIdBase}
          renderOptions={renderOptions}
          setIsOpen={(open): void => {
            setIsOpen(open);
            isOpenCallback?.(open);
          }}
          isOpen={isOpen}
          buttonProps={buttonProps}
          content={content}
          {...rest}
        />
      );
    case "multi-search":
      return (
        <MultiSearchDropdown<TValue>
          ref={refs.setReference}
          value={value as TValue[]}
          testIdBase={testIdBase}
          renderOptions={renderOptions}
          setIsOpen={(open): void => {
            setIsOpen(open);
            isOpenCallback?.(open);
          }}
          clearable={clearable}
          placeholder={placeholder}
          allowAdditions={allowAdditions}
          onAddItem={onAddItem}
          {...rest}
        />
      );
    default:
      throw Error(`${control} not yet implemented`);
  }
}

function SearchDropdownComponent<TValue>(
  {
    options,
    onChange,
    value,
    placeholder,
    size,
    disabled,
    clearable,
    testIdBase,
    setIsOpen,
    renderOptions,
    onAddItem,
  }: Omit<SearchDropdownProps<TValue>, "control"> & {
    renderOptions: RenderOptions<TValue>;
    setIsOpen: (open: boolean) => void;
  },
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element {
  const DROPDOWN_ITEM_ID = `dropdown-item-${uuidv4()}`;
  const DROPDOWN_SEARCH_ID = `dropdown-search-${uuidv4()}`;
  const [search, setSearch] = useState("");
  const [searchHasFocus, setSearchHasFocus] = useState(false);

  const filteredOptions = useMemo(() => {
    if (!search) return options;

    return options.filter(
      ({ text }) =>
        text.toLowerCase().search(escapeRegExp(search).toLowerCase()) != -1
    );
  }, [options, search]);

  const resetSearchState = (): void => {
    setSearch("");
    setSearchHasFocus(false);
  };
  const handleSelect = (value?: TValue): void => {
    resetSearchState();
    setIsOpen(false);
    onChange(value);
  };

  const getSelectedText = (): string => {
    if (searchHasFocus) return search;
    return options.find((opt) => opt.value === value)?.text || "";
  };

  const getPlaceholder = (): string => {
    if (!searchHasFocus) return placeholder;
    return options.find((opt) => opt.value === value)?.text || placeholder;
  };

  const shouldBlur = (relatedTarget: EventTarget & Element): boolean => {
    return !(
      relatedTarget &&
      [DROPDOWN_ITEM_ID, DROPDOWN_SEARCH_ID].includes(relatedTarget.id)
    );
  };

  const onAddItemMiddleware = (value: string): void => {
    resetSearchState();
    setIsOpen(false);
    onAddItem(value);
  };

  const onBlur = (relatedTarget: EventTarget & Element): void => {
    if (shouldBlur(relatedTarget)) {
      resetSearchState();
      setIsOpen(false);
    }
  };
  return (
    <div className="tw-relative">
      <div className="tw-relative">
        <input
          data-testid={
            testIdBase
              ? `${testIdBase}-dropdown-input-control`
              : "dropdown-input-control"
          }
          disabled={disabled}
          ref={ref}
          id={DROPDOWN_SEARCH_ID}
          type="text"
          className={[
            "tw-form-input",
            "tw-block",
            "tw-w-full",
            "tw-rounded-md",
            "tw-border-0",
            "tw-py-2",
            "tw-pr-8",
            "tw-text-gray-900",
            "tw-shadow-sm",
            "tw-ring-1",
            "tw-ring-inset",
            "tw-ring-gray-300",
            "focus:tw-ring-2",
            "focus:tw-ring-inset",
            "focus:tw-ring-gray-400",
            "disabled:tw-ring-gray-50",
            "disabled:tw-shadow-none",
            "disabled:tw-text-gray-400",
            "txu-dropdown-control",
            twSize(size),
            "txu-dropdown-control",
          ].join(" ")}
          value={getSelectedText()}
          placeholder={getPlaceholder()}
          onFocus={(): void => {
            setIsOpen(true);
            setSearchHasFocus(true);
          }}
          onChange={({ target }): void => setSearch(target.value)}
          onBlur={(event): void => {
            onBlur(event.relatedTarget);
          }}
        />
        {clearable && !!value ? (
          <i
            className={[
              "material-icons",
              "tw-absolute",
              "tw-right-2",
              "tw-top-[50%]",
              "-tw-translate-y-1/2",
              "tw-rounded",
              "tw-text-lg",
              "tw-leading-[1]",
              disabled
                ? "tw-text-gray-200 tw-cursor-default"
                : "tw-text-gray-500 tw-cursor-pointer hover:tw-ring-1 hover:tw-ring-inset hover:tw-ring-gray-500",
            ].join(" ")}
            onClick={(): void => {
              if (disabled) return;
              handleSelect();
            }}
            data-testid={
              testIdBase
                ? `${testIdBase}-dropdown-clear-selection`
                : "dropdown-clear-selection"
            }
          >
            close
          </i>
        ) : (
          <i
            className={[
              "material-icons",
              "tw-absolute",
              "tw-right-2",
              "tw-top-[50%]",
              "-tw-translate-y-1/2",
              "tw-text-xl",
              "tw-leading-[1]",
              "tw-z-10",
              disabled
                ? "tw-text-gray-200 tw-cursor-default"
                : "tw-text-gray-500 tw-cursor-text",
            ].join(" ")}
            onClick={(): void => {
              const inputRef = document.querySelector<HTMLInputElement>(
                `#${DROPDOWN_SEARCH_ID}`
              );
              if (!inputRef) return;
              inputRef.focus();
            }}
          >
            expand_more
          </i>
        )}
      </div>
      {renderOptions(
        filteredOptions,
        handleSelect,
        DROPDOWN_ITEM_ID,
        onBlur,
        search,
        onAddItemMiddleware
      )}
    </div>
  );
}

const SearchDropdown = forwardRef(SearchDropdownComponent) as <TValue>(
  props: Omit<SearchDropdownProps<TValue>, "control"> & {
    renderOptions: RenderOptions<TValue>;
    setIsOpen: (open: boolean) => void;
  } & {
    ref?: ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof SearchDropdownComponent>;

function MultiSearchDropdownComponent<TValue>(
  {
    options,
    onChange,
    value,
    placeholder,
    size: dropdownSize,
    disabled,
    clearable,
    testIdBase,
    setIsOpen,
    renderOptions,
    badgeProps,
    onAddItem,
  }: Omit<MultiSearchDropdownProps<TValue>, "control"> & {
    renderOptions: RenderOptions<TValue>;
    setIsOpen: (open: boolean) => void;
  },
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const DROPDOWN_ITEM_ID = `dropdown-item-${uuidv4()}`;
  const DROPDOWN_SEARCH_ID = `dropdown-search-${uuidv4()}`;
  const [search, setSearch] = useState("");

  const optionsLeft = useMemo(() => {
    if (!value) return options;

    return options.filter((opt) => !value.includes(opt.value));
  }, [options, value]);

  const filteredOptions = useMemo(() => {
    if (!search) return optionsLeft;

    return optionsLeft.filter(
      ({ text }) =>
        text.toLowerCase().search(escapeRegExp(search).toLowerCase()) != -1
    );
  }, [optionsLeft, search]);

  const resetSearchState = (): void => {
    setSearch("");
  };
  const handleSelect = (valueToAdd?: TValue): void => {
    resetSearchState();
    setIsOpen(false);
    if (!valueToAdd) {
      onChange([]);
      return;
    }
    onChange([...value, valueToAdd]);
  };

  const handleDeselect = (valueToRemove: TValue): void => {
    onChange(value.filter((value) => value !== valueToRemove));
  };

  const shouldBlur = (relatedTarget: EventTarget & Element): boolean => {
    return !(
      relatedTarget &&
      [DROPDOWN_ITEM_ID, DROPDOWN_SEARCH_ID].includes(relatedTarget.id)
    );
  };

  const onAddItemMiddleware = (value: string): void => {
    resetSearchState();
    setIsOpen(false);
    onAddItem(value);
  };

  const onBlur = (relatedTarget: EventTarget & Element): void => {
    if (shouldBlur(relatedTarget)) {
      resetSearchState();
      setIsOpen(false);
    }
  };

  const inputWidth = (): string => {
    if (search) return `${search.length}ch`;
    if (value?.length) return "1ch";
    if (placeholder?.length) return `${placeholder?.length}ch`;
    return "1ch";
  };
  return (
    <div className="tw-relative">
      <div
        data-testid={
          testIdBase ? `${testIdBase}-dropdown-wrapper` : "dropdown-wrapper"
        }
        tabIndex={1}
        ref={ref}
        className={[
          "tw-cursor-text",
          "tw-flex",
          "tw-gap-1",
          "tw-flex-wrap",
          "tw-w-full",
          "tw-rounded-md",
          "tw-border-0",
          "tw-py-2",
          "tw-pl-2",
          "tw-pr-8",
          "tw-text-gray-900",
          "tw-shadow-sm",
          "tw-ring-1",
          "tw-ring-inset",
          "tw-ring-gray-300",
          "has-[>input:focus]:tw-ring-2",
          "has-[>input:focus]:tw-ring-inset",
          "has-[>input:focus]:tw-ring-gray-400",
          "disabled:tw-ring-gray-50",
          "disabled:tw-shadow-none",
          "disabled:tw-text-gray-400",
          twSize(dropdownSize),
        ].join(" ")}
        onClick={(): void => {
          inputRef.current?.focus();
        }}
        onFocus={(): void => {
          inputRef.current?.focus();
        }}
      >
        {value.map((v, index) => (
          <Badge
            key={v as string}
            content={options.find(({ value }) => value == v)?.text}
            onClick={(): void => handleDeselect(v)}
            {...badgeProps}
            data-testid={
              testIdBase
                ? `${testIdBase}-dropdown-selected-item-${index}`
                : `dropdown-selected-item-${index}`
            }
          />
        ))}
        <input
          ref={inputRef}
          data-testid={
            testIdBase
              ? `${testIdBase}-dropdown-input-control`
              : "dropdown-input-control"
          }
          disabled={disabled}
          id={DROPDOWN_SEARCH_ID}
          style={{
            width: inputWidth(),
          }}
          type="text"
          className={`tw-border-0 txu-dropdown-control tw-form-input focus:tw-ring-0 tw-px-0 tw-py-1 ${twSize(
            dropdownSize
          )}`}
          value={search || ""}
          placeholder={value.length ? "" : placeholder}
          onFocus={(): void => {
            setIsOpen(true);
          }}
          onChange={({ target }): void => setSearch(target.value)}
          onBlur={(event): void => {
            onBlur(event.relatedTarget);
          }}
        />
        {clearable && !!value.length ? (
          <i
            className={[
              "material-icons",
              "tw-absolute",
              "tw-right-2",
              "tw-top-[50%]",
              "-tw-translate-y-1/2",
              "tw-rounded",
              "tw-text-lg",
              "tw-leading-[1]",
              disabled
                ? "tw-text-gray-200 tw-cursor-default"
                : "tw-text-gray-500 tw-cursor-pointer hover:tw-ring-1 hover:tw-ring-inset hover:tw-ring-gray-500",
            ].join(" ")}
            onClick={(): void => {
              if (disabled) return;
              handleSelect();
            }}
            data-testid={
              testIdBase
                ? `${testIdBase}-dropdown-clear-selection`
                : "dropdown-clear-selection"
            }
          >
            close
          </i>
        ) : (
          <i
            className={[
              "material-icons",
              "tw-absolute",
              "tw-right-2",
              "tw-top-[50%]",
              "-tw-translate-y-1/2",
              "tw-text-xl",
              "tw-leading-[1]",
              disabled
                ? "tw-text-gray-200 tw-cursor-default"
                : "tw-text-gray-500 tw-cursor-text",
            ].join(" ")}
            onClick={(): void => {
              const inputRef = document.querySelector<HTMLInputElement>(
                `#${DROPDOWN_SEARCH_ID}`
              );
              if (!inputRef) return;
              inputRef.focus();
            }}
          >
            expand_more
          </i>
        )}
      </div>
      {renderOptions(
        filteredOptions,
        handleSelect,
        DROPDOWN_ITEM_ID,
        onBlur,
        search,
        onAddItemMiddleware
      )}
    </div>
  );
}

const MultiSearchDropdown = forwardRef(MultiSearchDropdownComponent) as <
  TValue
>(
  props: Omit<MultiSearchDropdownProps<TValue>, "control"> & {
    renderOptions: RenderOptions<TValue>;
    setIsOpen: (open: boolean) => void;
  } & {
    ref?: ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof MultiSearchDropdownComponent>;

function ButtonDropdownComponent<TValue>(
  {
    options,
    onChange,
    setIsOpen,
    isOpen,
    renderOptions,
    buttonProps,
    content,
    testIdBase,
  }: Omit<ButtonDropdownProps<TValue>, "control"> & {
    renderOptions: RenderOptions<TValue>;
    setIsOpen: (open: boolean) => void;
    isOpen: boolean;
  },
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
  const DROPDOWN_ITEM_ID = `dropdown-item-${uuidv4()}`;
  const DROPDOWN_BUTTON_ID = `dropdown-button-${uuidv4()}`;
  const handleSelect = (value: TValue): void => {
    setIsOpen(false);
    onChange(value);
  };
  const shouldBlur = (relatedTarget: EventTarget & Element): boolean => {
    return !(
      relatedTarget &&
      [DROPDOWN_ITEM_ID, DROPDOWN_BUTTON_ID].includes(relatedTarget.id)
    );
  };
  const onBlur = (relatedTarget: EventTarget & Element): void => {
    if (shouldBlur(relatedTarget)) {
      setIsOpen(false);
    }
  };
  return (
    <div className="tw-relative" ref={ref}>
      <Button
        id={DROPDOWN_BUTTON_ID}
        data-testid={
          testIdBase
            ? `${testIdBase}-dropdown-button-control`
            : "dropdown-button-control"
        }
        {...buttonProps}
        onClick={(): void => setIsOpen(!isOpen)}
        onBlur={(event): void => {
          onBlur(event.relatedTarget);
        }}
      >
        {content}
      </Button>
      {renderOptions(options, handleSelect, DROPDOWN_ITEM_ID, onBlur)}
    </div>
  );
}

const ButtonDropdown = forwardRef(ButtonDropdownComponent) as <TValue>(
  props: Omit<ButtonDropdownProps<TValue>, "control"> & {
    renderOptions: RenderOptions<TValue>;
    setIsOpen: (open: boolean) => void;
    isOpen: boolean;
  } & {
    ref?: ForwardedRef<HTMLDivElement>;
  }
) => ReturnType<typeof ButtonDropdownComponent>;
