import React, { useState, useMemo } from "react";
import { Popup, PopupProps } from "semantic-ui-react";
import {
  Button as SUIButton,
  ButtonProps as SUIButtonProps,
} from "semantic-ui-react";

import {
  Button,
  Props as ButtonProps,
} from "../../../../components/tailwind/button/Button";
import { useGetCustomerQuery } from "../../../../api/customerApi";
import { TranslateButton } from "../../../../components/TranslateButton";
import { getFeatureFlag } from "../../../../utils/featureFlags";

const defaultIconButtonProps: SUIButtonProps = {
  size: "mini",
  color: "red",
  basic: true,
  compact: true,
};

type RevertButtonProps = {
  onRevert: () => void;
  popupProps?: PopupProps;
} & SUIButtonProps;
export const RevertButton: React.FC<RevertButtonProps> = ({
  onRevert,
  popupProps = { content: "Revert changes", size: "small" },
  ...rest
}) => {
  const buttonProps: SUIButtonProps = {
    ...defaultIconButtonProps,
    ...rest,
    icon: "undo",
  };
  return (
    <Popup
      {...popupProps}
      trigger={<SUIButton onClick={onRevert} {...buttonProps} />}
    />
  );
};

type CopyButtonProps = {
  onCopy: () => void;
  popupProps?: PopupProps;
} & SUIButtonProps;
export const CopyButton: React.FC<CopyButtonProps> = ({
  onCopy,
  popupProps = { content: "Copy to all other Prompts in group", size: "small" },
  ...rest
}) => {
  const buttonProps: SUIButtonProps = {
    ...defaultIconButtonProps,
    ...rest,
    icon: "copy",
  };
  return (
    <Popup
      {...popupProps}
      trigger={<SUIButton onClick={onCopy} {...buttonProps} />}
    />
  );
};

type SettingsButtonProps = {
  popupProps?: PopupProps;
} & SUIButtonProps;
export const SettingsButton: React.FC<SettingsButtonProps> = ({
  popupProps = { content: "Prompt settings", size: "small" },
  ...rest
}) => {
  const buttonProps: SUIButtonProps = {
    ...defaultIconButtonProps,
    ...rest,
    icon: "setting",
  };
  return <Popup {...popupProps} trigger={<SUIButton {...buttonProps} />} />;
};

export const GenerateButton: React.FC<
  {
    onGenerate: () => Promise<void>;
    popupProps?: PopupProps;
  } & ButtonProps
> = ({ onGenerate, popupProps = { disabled: true }, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  const disabled = (rest.disabled || rest.loading) && !isLoading;
  return (
    <Popup
      {...popupProps}
      trigger={
        <Button
          onClick={(): void => {
            if (rest.loading || rest.disabled || isLoading) return;
            setIsLoading(true);
            onGenerate().finally(() => setIsLoading(false));
          }}
          disabled={disabled}
          loading={isLoading}
          {...rest}
        />
      }
    />
  );
};

export const GroupedGenerationButtons: React.FC<
  {
    onGenerate: (
      autoTranslateAfterGeneration?: boolean,
      configGroupId?: number
    ) => Promise<void>;
    generateTestId?: string;
    generateAndTranslateTestId?: string;
    popupProps?: PopupProps;
  } & Omit<ButtonProps, "onClick">
> = ({ onGenerate, generateTestId, generateAndTranslateTestId, ...props }) => {
  const { data: customer } = useGetCustomerQuery();
  const ffNewTranslateButton = useMemo(() => {
    return getFeatureFlag(customer, "machine_translation_settings");
  }, [customer]);
  return (
    <>
      <GenerateButton
        data-testid={generateTestId}
        onGenerate={(): Promise<void> => onGenerate(false)}
        {...props}
      >
        Generate
      </GenerateButton>
      {ffNewTranslateButton ? (
        <TranslateButton
          data-testid={generateAndTranslateTestId}
          onClick={(configGroupId): void => {
            onGenerate(true, configGroupId);
          }}
          {...props}
        >
          Generate & Translate
        </TranslateButton>
      ) : (
        <GenerateButton
          data-testid={generateAndTranslateTestId}
          onGenerate={(): Promise<void> => onGenerate(true)}
          {...props}
        >
          Generate & Translate
        </GenerateButton>
      )}
    </>
  );
};
